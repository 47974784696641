import React, { useEffect, useState } from "react";
import { Container, Grid, Divider } from "@material-ui/core";
import ReactPlayer from "react-player";
import axios from "../../../utils/axios-confing";
import {apiUrl} from "../../../utils/api-app";

const Works = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get("api/pages/48")
      .then(res => setData(res.data))
      .catch(err => console.warn({ err }));
  }, []);
  return (
    <div className="works">
      <Container maxWidth="lg">
        <div className="component">
          <h2 className="component_title">{data && data.name}</h2>
          <div className="component_content">
            <div className="component_content_section">
              <h3 className="component_content_section_subtitle">
                Фото
                <Divider />
              </h3>
              <Grid container spacing={2}>
                {data &&
                  data.attachments.map((img, i) => (
                    <Grid key={i} item md={3} xs={12}>
                      <img
                        className="component_content_section_img-works"
                        src={apiUrl + '/assets/' + img.file}
                        alt="Наша работа"
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
            <p>{""}</p>
            <div className="component_content_section">
              <h3 className="component_content_section_subtitle">
                {data && data.our_works && data.our_works[0].name}
                <Divider />
              </h3>
              <div className="component_content_section_media">
                <Grid container spacing={2}>
                  {data &&
                    data.our_works[0].list.map(link => (
                      <Grid key={link.url} item md={6} xs={12}>
                        <ReactPlayer
                          className="component_content_section_media_video"
                          width="100%"
                          controls={false}
                          url={link.url}
                        />
                      </Grid>
                    ))}
                </Grid>
              </div>
            </div>
            <div className="component_content_section">
              <h3 className="component_content_section_subtitle">
                {data && data.our_works[1].name}
                <Divider />
              </h3>
              <div className="component_content_section_media">
                <Grid container spacing={2}>
                  {data &&
                    data.our_works[1].list.map(link => (
                      <Grid key={link.url} item md={6} xs={12}>
                        <ReactPlayer
                          className="component_content_section_media_video"
                          width="100%"
                          controls={false}
                          url={link.url}
                        />
                      </Grid>
                    ))}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Works;

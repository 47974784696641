import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import ReactPlayer from "react-player";
import axios from "../../../utils/axios-confing";

const Construction = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get("api/pages/3")
      .then(res => setData(res.data))
      .catch(err => console.warn({ err }));
  }, []);
  return (
    <div className="construction">
      <Container maxWidth="lg">
        <div className="component">
          <div className="component_content">
            <h2 className="component_title">{data && data.name}</h2>
            <div className="component_content_media">
              <Grid container spacing={2}>
                {data &&
                  data.videos.map(video => (
                    <Grid key={video.url} item md={6} xs={12}>
                      <ReactPlayer
                        className="component_content_section_media_video"
                        width="100%"
                        controls={false}
                        url={video.url}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Construction;

import React from "react";
import { Route, Switch } from "react-router";
import MainContent from "../containers/main/MainContent";
import Vacancies from "../components/SimpleComponents/Vacancies";
import ContactsPage from "../components/SimpleComponents/ContactsPage";
import Projects from "../components/SimpleComponents/Projects";
import Stocks from "../components/SimpleComponents/Stocks";
import Works from "../components/SimpleComponents/Works";
import Construction from "../components/SimpleComponents/Construction";
import Similar from "../components/SimpleComponents/Similar";
import TenYears from "../components/SimpleComponents/TenYears";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={MainContent} />
      <Route path="/page/1" component={Projects} />
      <Route path="/page/3" component={Construction} />
      <Route path="/page/4" component={Stocks} />
      <Route path="/page/48" component={Works} />
      <Route path="/page/46" component={ContactsPage} />
      <Route path="/page/45" component={Vacancies} />
      <Route path="/page/52" component={TenYears} />
      <Route path="/page/:id" component={Similar} />
      <Route component={MainContent} />
    </Switch>
  );
};

export default Routes;

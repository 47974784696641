import React, { useState } from "react";

import { Container } from "@material-ui/core";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";

import "./Contacts.sass";
const Contacts = () => {
  const [mapOptions] = useState({
    lat: 47.0414,
    lng: 28.81087,
    zoom: 16,
    displayPopUp: true
  });
  const position = [mapOptions.lat, mapOptions.lng];

  const openPopup = marker => {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup();
      });
    }
  };
  const AddressCard = () => (
    <div className="map-card">
      <h4 className="map-card_title">Viknograd</h4>
      <div className="map-card_address">Адресс: Букурией 7</div>
      <div className="map-card_sub-title">Транспорт</div>
      <div className="map-card_transport">
        <span className="map-card_transport_title">Тролейбусы:</span>
        <span className="map-card_transport_text">3, 21, 22</span>
      </div>
      <div className="map-card_transport">
        <span className="map-card_transport_title">Автобусы:</span>
        <span className="map-card_transport_text">26</span>
      </div>
      <div className="map-card_transport">
        <span className="map-card_transport_title">Маршрутки:</span>
        <span className="map-card_transport_text">
          103, 121, 129, 145, 172, 185
        </span>
      </div>
    </div>
  );

  return (
    <section className="contacts-section">
      <h4 className="section-title">Контакты</h4>
      <Container maxWidth="lg">
        <div className="contacts-map">
          <Map
            className="contacts-map_inner"
            center={position}
            zoom={mapOptions.zoom}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="Viknograd"
            />
            <Marker position={position} ref={openPopup}>
              <Popup>
                <AddressCard />
              </Popup>
            </Marker>
          </Map>
        </div>
      </Container>
    </section>
  );
};

export default Contacts;

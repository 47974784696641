import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Teammate from "../Teammate";
import axios from "../../../utils/axios-confing";

const ContactsPage = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("api/pages/46")
      .then(res => setData(res.data.contacts))
      .catch(err => console.warn({ err }));
  }, []);
  return (
    <div className="contacts-page">
      <Container maxWidth="lg">
        <div className="component">
          <h2 className="component_title">Контакты</h2>
          <div className="component_content">
            <div className="component_content_section">
              {Object.values(data).map(address => (
                <div
                  className="component_content_section_contacts-box"
                  key={address.city}
                >
                  <h4 className="component_content_section_contacts-box_name">
                    {address.city}
                  </h4>
                  <Grid
                    container
                    className="component_content_section_cards"
                    spacing={2}
                  >
                    {Object.values(address.persons).map(user => (
                      <Grid key={user.header} item md={3} sm={6}>
                        <Teammate user={user} />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactsPage;

import React from "react";
import { Card, CardContent, CardMedia, Divider } from "@material-ui/core";
import "./Teammate.sass";

const Teammate = ({ user }) => (
  <div className="teammate">
    <Card className="teammate_box">
      <CardMedia
        className="teammate_box_img"
        component="img"
        alt={user.header}
        image={user.file_url}
      />
      <CardContent className="teammate_box_content">
        <h2 className="teammate_box_content_name">{user.header}</h2>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <div dangerouslySetInnerHTML={{ __html: user.body }} />
      </CardContent>
    </Card>
  </div>
);

export default Teammate;

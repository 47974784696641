import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Button, Fab } from "@material-ui/core";
import "./Feedback.sass";

const Feedback = () => {
  const [activeFeedback, setActiveFeedback] = useState(false);
  const showFeedback = () => {
    setActiveFeedback(!activeFeedback);
  };
  const showModal = () => {
    setTimeout(() => {
      setActiveFeedback(!activeFeedback);
    }, 5000);
  };

  useEffect(() => {
    showModal();
  }, []);

  return (
    <div className="feedback">
      <Fab
        className={`feedback_show ${activeFeedback && "active"}`}
        onClick={showFeedback}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </Fab>
      <div className={`feedback_inner ${activeFeedback && "active"}`}>
        <Button className="feedback-close" onClick={showFeedback}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <iframe
          id="feedback"
          className="feedback-frame"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfrXlGyeU_17BIDrYmdwvpn3x22LCGKrOF0Ko_IaKwldGm3WQ/viewform?embedded=true"
          frameBorder="0"
          title="Обратная связь"
        />
        <div className="feedback-over" />
      </div>
    </div>
  );
};

export default Feedback;

import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Box, Container, Button, IconButton, Drawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import axios from "../../../utils/axios-confing";
import { useLocation } from "react-router-dom";
import "./Header.sass";

const Header = () => {
  const history = useHistory();
  const [menuState, setMenuState] = useState({
    right: false
  });
  const [menuList, setMenuList] = useState([]);
  const { pathname } = useLocation();
  const menuRef = useRef("");

  useEffect(() => {
    if (pathname !== "/") {
      window.scrollTo(0, menuRef.current.offsetTop);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, menuRef]);

  useEffect(() => {
    axios
      .get("api/menu-items")
      .then(res => setMenuList(res.data))
      .catch(err => console.warn({ err }));
  }, []);

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMenuState({ ...menuState, right: open });
  };
  const headersPush = link => {
    const exceptionList = [2, 27, 28, 40, 47];
    if (exceptionList.includes(link.page_id)) {
      history.push("/");
    } else {
      history.push("/page/" + link.page_id);
    }
  };

  const navbar = () => (
    <Box className="navbar_menu">
      {menuList &&
        menuList.map(link => (
          <Box key={link.name} className="navbar_menu_button">
            <div
              onClick={() => headersPush(link)}
              className="navbar_menu_button_title"
            >
              {link.name}
            </div>
            <div className="dropdown-menu">
              {link.content &&
                link.content.map(item => (
                  <Button
                    key={item.name}
                    onClick={() => history.push("/page/" + item.page_id)}
                    className="dropdown-menu_link"
                  >
                    {item.name}
                  </Button>
                ))}
            </div>
          </Box>
        ))}
    </Box>
  );

  const sideList = side => (
    <div
      className="side-menu"
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      {navbar()}
    </div>
  );

  return (
    <div className="header">
      <div className="header_top-content">
        <Container maxWidth={false} className="header_top-content_inner">
          <Box className="head">
            <Box className="head_logo">
              <div onClick={() => history.push("/")} className="head_logo_link">
                <h3 className="head_logo_link_text">viknograd</h3>
              </div>
            </Box>
            <Box className="head_contacts">
              <a className="head_contacts_link" href="tel: +373 22 753 588">
                +373 22 753 588
              </a>
              <a
                className="head_contacts_link"
                href="mailto: info@viknograd.md"
                type="email"
              >
                info@viknograd.md
              </a>
            </Box>
          </Box>
          <Box className="banner-slogan">
            <Box className="banner-slogan_inner">
              <div className="banner-slogan_inner_text">
                Все для производства окон из ПВХ и алюминия
              </div>
            </Box>
          </Box>
        </Container>
      </div>
      {/*<ScrollToTop menuRef={menuRef} />*/}
      <div ref={menuRef} className="header_menu">
        <Container maxWidth={false}>
          <Drawer
            open={menuState.right}
            anchor="right"
            onClose={toggleDrawer("right", false)}
          >
            {sideList("right")}
          </Drawer>
          <Box className="navbar">
            {navbar()}
            <Box className="navbar_menu-hidden">
              <IconButton
                edge="start"
                href="#text-button"
                className="menu_icon"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer("right", true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(Header);

import React, { useEffect, useState } from "react";
import {
  Container,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Vacancies.sass";
import axios from "../../../utils/axios-confing";

const Vacancies = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("api/pages/45")
      .then(res => setData(res.data))
      .catch(err => console.warn({ err }));
  }, []);
  return (
    <div className="vacancies">
      <Container maxWidth="lg">
        <div className="component">
          <h2 className="component_title">{data.page_header}</h2>
          <div className="component_content">
            <div className="component_content_section">
              {data.inline_blocks &&
                data.inline_blocks.map(vacancy => (
                  <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    className="component_content_section_expansion"
                  >
                    <ExpansionPanelSummary
                      className="component_content_section_expansion_title"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      <h3 className="component_content_section_expansion_title_inner">
                        {vacancy.name}
                      </h3>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="component_content_section_expansion_content">
                      <div dangerouslySetInnerHTML={{ __html: vacancy.body }} />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Vacancies;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-5-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../node_modules/resolve-url-loader/index.js??ref--6-oneOf-5-3!../../../../node_modules/sass-loader/dist/cjs.js??ref--6-oneOf-5-4!slick-carousel/slick/slick.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-5-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../node_modules/resolve-url-loader/index.js??ref--6-oneOf-5-3!../../../../node_modules/sass-loader/dist/cjs.js??ref--6-oneOf-5-4!slick-carousel/slick/slick-theme.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".news-section {\n  width: 100%;\n  background: #eaeaea;\n  position: relative;\n  padding-bottom: 60px; }\n  .news-section .section-title {\n    border-bottom: 1px solid #8b8789;\n    margin: 10px 0 40px 0; }\n    @media (min-width: 768px) {\n      .news-section .section-title {\n        margin: 10px 0 60px 0; } }\n\n.news {\n  position: relative;\n  width: 100%;\n  justify-content: space-between; }\n  .news .slick-prev {\n    margin-left: -40px; }\n  .news .slick-next {\n    padding-left: 60px; }\n  .news .slick-arrow {\n    width: 40px;\n    height: 40px; }\n    .news .slick-arrow:before {\n      font-size: 40px;\n      color: #8b8789; }\n  .news_item {\n    cursor: pointer;\n    padding-bottom: 60px; }\n    @media (min-width: 768px) {\n      .news_item {\n        padding: 0 15px; } }\n    .news_item_card_content_img {\n      width: 100%;\n      background-size: cover !important;\n      min-height: 350px; }\n    .news_item_card_content_name {\n      font-size: 22px;\n      margin: 0 0 10px !important;\n      line-height: 50px; }\n      @media (min-width: 768px) {\n        .news_item_card_content_name {\n          font-size: 20px !important; } }\n    .news_item_card_content_description {\n      font-size: 18px;\n      letter-spacing: .5px; }\n    .news_item_card_actions_text {\n      color: #8b8789;\n      padding-left: 10px; }\n", ""]);
// Exports
module.exports = exports;

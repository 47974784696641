import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import axios from "../../../utils/axios-confing";
import {apiUrl} from '../../../utils/api-app'
import ReactPlayer from "react-player";

const Similar = props => {
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    const pageId = props.match.params.id;
    setId(pageId);
    if (id !== props.match.params.id) {
      const pageId = props.match.params.id;
      axios
        .get(`api/pages/${pageId}`)
        .then(res => setData(res.data))
        .catch(err => console.warn({ err }));
    }
  }, [setId, id, props.match.params.id]);

  const quantityImages = () => {
    if (data && data.attachments.length > 3) return 3;
    if (data && data.attachments.length === 3) return 4;
    if (data && data.attachments.length === 2) return 6;
    if (data && data.attachments.length === 1) return 12;
  };

  const quantityVideos = () => {
    if (data && data.videos.length > 3) return 3;
    if (data && data.videos.length === 3) return 4;
    if (data && data.videos.length === 2) return 6;
    if (data && data.videos.length === 1) return 12;
  };
  let body = data && data.body;

  if (data && data.inline_blocks) {
    data.inline_blocks.forEach(item => {
      body = body.replace(
        new RegExp(`yield:${item.name}`, "g"),
        `<div class="inline-block">` +
          `<span class="inline-body">${item.body}</span>` +
          `<img class="inline-image" src="${item.file_url}" alt="${item.name}" />` +
          `</div>`
      );
    });
  }
  console.warn(data)
  return (
    <div className="similar">
      <Container maxWidth="lg">
        <div className="component">
          <h2 className="component_title">{data && data.page_header}</h2>
          <div className="component_content">
            {data && data.videos.length === 0 && (
              <Grid container spacing={2}>
                {data &&
                  data.attachments.map((att, i) => (
                    <Grid key={att.file} item xs={quantityImages()}>
                      <img
                        className={`component_content_img 
                        ${quantityImages() === 3 && "image_3"} 
                        ${quantityImages() === 4 && "image_4"} 
                        ${quantityImages() === 6 && "image_6"} 
                        ${quantityImages() === 12 && "image_12"}`}
                        src={apiUrl + "/assets/" + att.file}
                        alt={"att" + i}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
            {data && data.videos.length === 1 && data.attachments.length === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <img
                    className="component_content_media_img"
                    src={
                      apiUrl + "/assets/" + data.attachments[0].file
                    }
                    alt="media"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ReactPlayer
                    className="component_content_media_video"
                    width="100%"
                    controls={false}
                    url={data.videos[0].url}
                  />
                </Grid>
              </Grid>
            )}
            {data && data.videos.length === 1 && data.attachments.length === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <img
                    className="component_content_media_img"
                    src={
                      apiUrl + "/assets/" + data.attachments[0].file
                    }
                    alt="media"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    className="component_content_media_img"
                    src={
                      apiUrl + "/assets/" + data.attachments[1].file
                    }
                    alt="media"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ReactPlayer
                    className="component_content_media_video"
                    width="100%"
                    controls={false}
                    url={data.videos[0].url}
                  />
                </Grid>
              </Grid>
            )}
            {data && data.attachments.length === 0 && (
              <Grid container spacing={2}>
                {data.videos.map(video => (
                  <Grid key={video.url} item xs={12} md={quantityVideos()}>
                    <ReactPlayer
                      className="component_content_video"
                      width="100%"
                      controls={false}
                      url={video.url}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {data && (
              <div
                className="component_content_section"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Similar;

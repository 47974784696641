import React from "react";
import { Container } from "@material-ui/core";
import ReactPlayer from "react-player";

const About = ({ header, content, video }) => {
  return (
    <section className="about-section">
      <Container maxWidth="lg">
        <h4 className="section-title">{header}</h4>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <ReactPlayer
          className="about-section_video"
          width="100%"
          controls={true}
          url={video && video[0].url}
        />
      </Container>
    </section>
  );
};

export default About;

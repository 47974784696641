import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import axios from "../../../utils/axios-confing";
import {apiUrl} from '../../../utils/api-app'

const Stocks = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get("api/pages/4")
      .then(res => setData(res.data))
      .catch(err => console.warn({ err }));
  }, []);

  return (
    <div className="stocks">
      <Container maxWidth="lg">
        <div className="component">
          <h2 className="component_title">{data && data.name}</h2>
          <div className="component_content">
            <div className="component_content_section">
              <Grid container spacing={4}>
                {data &&
                  data.attachments.map((stock, i) => (
                    <Grid key={stock.file} item xs={12}>
                      <img
                        className="component_content_section_stocks-img"
                        src={apiUrl + "/assets/" + stock.file}
                        alt={"stock" + i}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Stocks;

import React, { useEffect, useState } from "react";
import About from "../../components/MainComponents/About/About";
import News from "../../components/MainComponents/News";
import Contacts from "../../components/MainComponents/Contacts";
import axios from "../../utils/axios-confing";

const MainContent = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("api/pages/2")
      .then(res => setData(res.data))
      .catch(err => console.warn({ err }));
  }, []);
  return (
    <div className="content_inner">
      <About
        header={data && data.page_header}
        content={data && data.body}
        video={data && data.videos}
      />
      <News news={data && data.news} />
      <Contacts />
    </div>
  );
};

export default MainContent;

import axios from "axios";
import {apiUrl} from './api-app'
const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "application/json, /",
    "Content-Type": "application/json"
  }
});

export default instance;

import React from "react";
import { useHistory } from "react-router";
import {
  Container,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography
} from "@material-ui/core";
import Slider from "react-slick";

import "./News.sass";
import { settings } from "../../../utils/slider.settings";
import {apiUrl} from "../../../utils/api-app";

const News = ({ news }) => {
  const history = useHistory();

  return (
    <section className="news-section">
      <h4 className="section-title">Новости</h4>

      <Container maxWidth="lg">
        <div className="news">
          <Slider {...settings}>
            {news &&
              news.reverse().map((item, i) => (
                <div className="news_item" key={item.name}>
                  <Card
                    className="news_item_card"
                    onClick={() => history.push("/page/" + item.id)}
                  >
                    <CardActionArea className="news_item_card_content">
                      <CardMedia
                        className="news_item_card_content_img"
                        image={
                          apiUrl + "/assets/" + item.thumbnail_url
                        }
                      />
                      <CardContent>
                        <Typography
                          className="news_item_card_content_name"
                          gutterBottom
                          variant="h5"
                          component="h2"
                        >
                          {item.name}
                        </Typography>
                        {item.description && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className="news_item_card_content_text"
                            component="p"
                          >
                            {item.description}
                          </Typography>
                        )}
                      </CardContent>
                    </CardActionArea>
                    <CardActions className="news_item_card_actions">
                      <span className="news_item_card_actions_text">
                        {item.created_at}
                      </span>
                    </CardActions>
                  </Card>
                </div>
              ))}
          </Slider>
        </div>
      </Container>
    </section>
  );
};
export default News;

import React from "react";

import { Container, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";

import "./Footer.sass";

const Footer = () => {
  return (
    <section className="footer-section">
      <Container maxWidth="lg">
        <Grid className="footer-wrapper" container component="div">
          <Grid
            className="footer-wrapper_block"
            item
            component="div"
            md={4}
            xs={12}
          >
            <h4 className="footer-wrapper_block_text left">Наши контакты</h4>
            <ul className="footer-wrapper_block_list">
              <li className="footer-wrapper_block_list_item">
                КИШИНЕВ:
                <a
                  className="footer-wrapper_block_list_item_url"
                  href="tel:+373(22)75-35-88"
                >
                  +373(22)75-35-88
                </a>
              </li>
              <li className="footer-wrapper_block_list_item">
                БЕЛЬЦЫ:
                <a
                  className="footer-wrapper_block_list_item_url"
                  href="tel:+373(231)4-79-65"
                >
                  +373(231)4-79-65
                </a>
              </li>
              <li className="footer-wrapper_block_list_item">
                КАГУЛ:
                <a
                  className="footer-wrapper_block_list_item_url"
                  href="tel:+(373 299)3-28-60"
                >
                  +(373 299)3-28-60
                </a>
              </li>
              <li className="footer-wrapper_block_list_item">
                ТИРАСПОЛЬ:
                <a
                  className="footer-wrapper_block_list_item_url"
                  href="tel:+(373533)76417"
                >
                  +(373533)76417
                </a>
              </li>
              <li className="footer-wrapper_block_list_item">
                РУМЫНИЯ:
                <a
                  className="footer-wrapper_block_list_item_url"
                  href="tel:(+40)213673200"
                >
                  (+40)213673200
                </a>
              </li>
            </ul>
          </Grid>
          <Grid
            className="footer-wrapper_block"
            item
            component="div"
            md={4}
            xs={12}
          >
            <h4 className="footer-wrapper_block_text">Мы в соцсетях</h4>
            <div className="socials">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="brand-icons"
                href="https://www.facebook.com/ViknogradConstruct"
              >
                <FontAwesomeIcon
                  className="brand-icons_img"
                  icon={faFacebook}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="brand-icons"
                href="https://www.youtube.com/channel/UCzAeiQZwk-zS9vLio4uCjAQ"
              >
                <FontAwesomeIcon className="brand-icons_img" icon={faYoutube} />
              </a>
            </div>
          </Grid>
          <Grid
            className="footer-wrapper_block"
            item
            component="div"
            md={4}
            xs={12}
          >
            <h4 className="footer-wrapper_block_text right">Режим работы</h4>
            <ul className="footer-wrapper_block_list">
              <li className="footer-wrapper_block_list_item">
                Пн-Чт: с 08:00 до 18:00
              </li>
              <li className="footer-wrapper_block_list_item">
                Пт: с 8:00 до 17:00
              </li>
              <li className="footer-wrapper_block_list_item">
                Сб: с 9:00 до 13:00
              </li>
              <li className="footer-wrapper_block_list_item">Вс - Выходной</li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import Gallery from "react-grid-gallery";
import axios from "../../../utils/axios-confing";
import {apiUrl} from "../../../utils/api-app";
const TenYears = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get("api/pages/52")
      .then(res => setData(res.data))
      .catch(err => console.warn({ err }));
  }, []);

  const images = [];
  data &&
    data.attachments.map((img, index) => {
      images.push({
        src: apiUrl + "/assets/" + img.file,
        thumbnail: apiUrl + "/assets/" + img.file,
        thumbnailWidth: 320,
        thumbnailHeight: index !== 6 ? 200 : 380
      });
      return null;
    });

  return (
    <div className="event_block">
      <Container maxWidth="lg">
        <div className="component">
          <h2 className="component_title">Нам 10 лет</h2>
          <div className="component_content">
            <div
              className="component_content_section"
              style={{ display: "flex" }}
            >
              <div className="component_content_section_gallery">
                <Gallery images={images} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TenYears;

import React from "react";
import { Card, CardContent, CardMedia } from "@material-ui/core";
import "./Project.sass";

const Project = ({ project }) => (
  <a
    className="project"
    href={project.url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Card className="project_card">
      <CardMedia
        className="project_card_img"
        component="img"
        alt="qwe"
        image={project.file_url}
      />
      <CardContent className="project_card_desc">
        <h4 className="project_card_desc_title">{project.name}</h4>
        <p className="project_card_desc_price">{project.body}</p>
      </CardContent>
    </Card>
  </a>
);

export default Project;
